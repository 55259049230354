import styled, { css } from 'styled-components';

const RegularFont = 'Rubik-Regular';
const BoldRubikFont = 'Rubik-Medium';
const MontserratRegular = 'Montserrat-Regular';
const NavBarContainer = styled.div`
  width: 100%;
  display:flex;
  font-family: ${RegularFont};
  background-color: #FFFFFF;
  margin: 0 auto;
  position:relative;
  max-width: 1200px;
  > picture {
    float: left;
    padding-right: 30px;
    margin: 15px 0px;
    > img {
      height: 40px;
      float: left;
      cursor: pointer;
      max-width: 140px;
      @media (max-width: 430px) {
        width: 119px;
        height: 45px;
        object-fit: contain;
        margin-top: 7px;
        margin-bottom:7px
      }
    }
    @media (max-width: 435px) {
      margin: 0;
    }
  }

  @media(max-width: 1200px){
    display: flex;
    flex-direction: column;
    padding: 0px;
    height: 100%;
    position: sticky;
    >picture{
      padding-left: 15px;
    }
  }
  @media (min-width: 1600px) {
    transition: 0.5s ease-in-out;
  }
  
  @media (max-width: 435px) {
    padding: 0;
    box-shadow: 0px 11px 14px 0px #0000001a;
    z-index: 1;
  }
`;

const Button = styled.button`
  height: 40px;
  padding: 0 20px;
  white-space: nowrap;
  font-size: ${({fontSize}) => fontSize || '13px'};
  font-weight: bold;
  line-height: 1.428571429;
  background-color: ${(props) => props.color || 'rgb(30, 118, 171)'};
  color: #fff;
  border-radius: 3px;
  border: none;
  letter-spacing: 1px;

  &:first-child {
    margin-right: 10px;
    margin-left: 10px;
  }
  
  &:focus, &:active, &:hover {
    outline: none;
    box-shadow: 0 7px 8px -1px rgba(30, 118, 171, 0.3);
    transition: all 200ms ease;
  }
  
  @media (max-width: 500px) {
    padding: 0 10px;
  }
`;

const PortalButton = styled.button`
  height: 40px;
  padding: 0 20px;
  width: 138px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.428571429;
  background-color: transparent;
  color: #F6797D;
  border-radius: 3px;
  border: 2px solid #F6797D;
  letter-spacing: 1px;
  display: ${(props) => props.show ? 'inline-block': 'none'};
  &:first-child {
    margin-right: 12px;
    margin-left: 10px;
  }
  
  &:focus, &:active, &:hover {
    outline: none;
    background-color: #F6797D;
    color: #fff;
    font-size: 16px;
    box-shadow: 0 7px 8px -1px rgba(30, 118, 171, 0.3);
    transition: all 200ms ease;
    height: 40px;
  }
  
  @media (max-width: 500px) {
    padding: 0 10px;
    &:first-child {
      margin-right: 10px;
  }
  
  }
`;

const Sigin = styled.button`

    height: 40px;
    padding: 0px 20px;
    width: 138px;
    white-space: nowrap;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.42857;
    background-color: transparent;
    color: rgb(246, 121, 125);
    border-radius: 3px;
    border: 2px solid rgb(246, 121, 125);
    letter-spacing: 1px;
    display: inline-block;
    align-items: center;
  justify-content: center;

  margin-right: 12px;
  font-weight: 700;
  font-family: ${BoldRubikFont};
  color: ${(props) => props.color || 'rgb(136,182,90)'};
  cursor: pointer;
  &:focus, &:active, &:hover {
    outline: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 22px 0px 22px;
    background-color: #F6797D;
    color: #fff;
    font-size: 16px;
    transition: all 200ms ease;
    height: 40px;
  }
  
  > img { 
    margin-right: 12px;
  }
  
  @media (max-width: 500px) {
    padding: 0 10px;
  }  
`;

const ButtonContainer = styled.div`
  display: inline-block;
  float: right;
`;

const PageLinkWrapper = styled.li`
  padding-left: 5vw;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  
  > a {
    font-size: 14px;
    color: rgb(139,139,139);
    text-transform: capitalize;
    &:hover, &:focus, &:active {
      color: rgb(116,220,227);
      text-decoration: none;
    }
  }
  
  ${({active}) => active && css`
    > a {
      color: rgb(116,220,227);
    }
  `}
  
  > div {
    height: 6px;
    width: 6px;
    margin-top: 8px;
    margin-right: 5px;
    background-color: rgb(116,220,227);
    float: left;
    line-height: 10px;
    border-radius: 50%;
  }
`;

const PageLinkContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  float: left;
  
  > ul {
    top: 0;
    right: 0;
    z-index: 0;
    float: right;
    position: relative;
    float: right;
    box-shadow: none;
  }
  
  @media (max-width: 1095px) {
    margin-top: 20px;
  }
`;


const CustomListItem = styled.li`
  margin: 10px 0px 0px 0px;
  padding-bottom: 17px;
  border-bottom: ${({ active }) => active ? '3px solid #F6797D' : '3px solid transparent'};
  border-radius: unset;
  text-align: center;
  cursor: pointer;
  list-style-type: none;
  position: relative;
  display: ${({ showTab }) => showTab ? 'inline-block' : 'none'};
  color: #49b5d6;
  > a {
    float: left;
    text-transform: capitalize;
    font-size: 16px;
    color: ${({ active }) => active ? '#F6797D' : '#9c9c9c'};
    font-family: ${({ active }) => active ? 'Rubik-Regular' : 'Rubik-Regular !important'};
    > svg {
      fill: ${({ active }) => active ? '#F6797D' : '#C4C4C4'};
      width: 10px;
      margin-left: 3px;
    }
    &:hover, &:focus, &:active {
      text-decoration: none;
      color: ${({ active }) => active ? '#F6797D' : '#C4C4C4'};
      > svg {
        fill: #F6797D;
        width: 10px;
        margin-left: 3px;
      }
    }
  }
  &:hover, &:focus, &:active {
    border-bottom: 3px solid #F6797D;
    > a {
      text-decoration: none;
      color: #F6797D;
      font-family: 'Rubik-Medium';
    }
  }
  @media (max-width: 1200px) {
    min-width: 120px;
    border-left: ${({ active }) => active ? '5px solid #F6797D' : '5px solid transparent'};
    border-bottom: none;
    text-align: left;
    > a {
      color: ${({ active }) => active ? '#F6797D' : '#C4C4C4'};
      > svg {
        fill: ${({ active }) => active ? '#F6797D' : '#C4C4C4'};
      }
    }
    &:hover, &:focus, &:active {
      border-bottom:none;
        >a {
          text-decoration: none;
          color: #F6797D;
        }
    }
  }

  &:hover ul {
    display: block;
  }
  .platform-div {
    list-style: none;
    display: none;
    position: absolute;
    padding-left: 118px;
    height: 200px;
    flex-direction: row;
    width: 100vw;
    top: 42px;
    left: -234px;
    background-color: white;
    font-family: ${BoldRubikFont};
    z-index: 1;
    >div:last-child{
      cursor: default;
    }
    >div{
      width:17%;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: left;
      >h1{
        font-family: ${BoldRubikFont};
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 5px 0;
        color: #252530;
      }
      >p{
        font-family: ${MontserratRegular};
        font-style: normal;
        font-size: 14px;
        line-height: 15px;
        color: #252530;
      }
      &:hover{
        >h1{
        color: #F6797D;
        }
        >div{
          >svg {
            fill : #F6797D;
            >path{
              fill : #F6797D;
            }
          }
        }
      }
    }
    >div:last-child{
      width:32%;
      background: rgba(246, 121, 125, 0.14);
      padding: 16px 0 5px 30px;
      >h1{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 7px;
        color: #243A5A;
      }
      >ul{
        padding-left: 20px;
        li{
          font-family: Rubik;
          padding: 5px 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #979797;
        }
      }
    }
  }
  &:hover .platform-div{
    display: flex;
    justify-content: center;
  }
  ul > li:hover {
    color: #F6797D;
  }

  .solution-div {
    list-style: none;
    display: none;
    position: absolute;
    padding-left: 118px;
    height: 200px;
    flex-direction: row;
    width: 100vw;
    top: 42px;
    left: -234px;
    background-color: white;
    font-family: ${BoldRubikFont};
    z-index: 1;
    >div:last-child{
      cursor: default;
    }
    >div{
      width:17%;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: left;
      >h1{
        font-family: ${BoldRubikFont};
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 5px 0;
        color: #252530;
      }
      >p{
        font-family: ${MontserratRegular};
        font-style: normal;
        font-size: 14px;
        line-height: 15px;
        color: #252530;
      }
      &:hover{
        >h1{
        color: #F6797D;
        }
        >div{
          display: flex;
          >svg {
            fill : #F6797D;
            >path{
              fill : #F6797D;
            }
          }
          >h1{
            font-family: ${BoldRubikFont};
            font-style: normal;
            font-size: 16px;
            line-height: 19px;
            margin: 5px 0;
            color: #24395A;
            padding: 8px 0px 8px 0px;
          }
        }
      }
    }
    >div:last-child{
      width:32%;
      background: rgba(246, 121, 125, 0.14);
      padding: 16px 0 5px 30px;
      >h1{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 7px;
        color: #243A5A;
      }
      >ul{
        padding-left: 20px;
        li{
          font-family: Rubik;
          padding: 5px 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #979797;
        }
      }
    }
  }
  &:hover .solution-div{
    display: flex;
  }
  ul > li:hover {
    color: #F6797D;
  }
`;

const CustomListItemNavBar = styled.li`
  margin: 10px 31px -7px 14px;
  border-bottom: ${({ active }) => active ? '3px solid #F6797D' : '3px solid transparent'};
  border-radius: unset;
  text-align: center;
  cursor: pointer;
  list-style-type: none;
  display: ${({ showTab }) => showTab ? 'inline-block' : 'none'};
  color: #49b5d6;
  > a {
    float: left;
    text-transform: capitalize;
    font-size: 16px;
    color: ${({ active }) => active ? '#F6797D' : '#24395A'};
    font-family: ${({ active }) => active ? 'Rubik-Regular' : 'Rubik-Regular !important'};
    > svg {
      fill: ${({ active }) => active ? '#F6797D' : '#C4C4C4'};
      width: 10px;
      margin-left: 3px;
    }
    &:hover, &:focus, &:active {
      text-decoration: none;
      color: ${({ active }) => active ? '#F6797D' : '#C4C4C4'};
      > svg {
        fill: #F6797D;
        width: 10px;
        margin-left: 3px;
      }
    }
  }
  &:hover, &:focus, &:active {
    border-bottom: 3px solid #F6797D;
    // margin-bottom: -7px;
    margin-bottom: ${({ active }) => active ? '-7px' : '-7px'}
    > a {
      text-decoration: none;
      color: #F6797D;
      font-family: 'Rubik-Regular';
    }
  }
  @media (max-width: 1200px) {
    min-width: 120px;
    border-left: ${({ active }) => active ? '5px solid #F6797D' : '5px solid transparent'};
    border-bottom: none;
    text-align: left;
    > a {
      display: flex;
      color: ${({ active }) => active ? '#F6797D' : '#0D4270'};
      > svg {
        fill: ${({ active }) => active ? '#F6797D' : '#0D4270'};
        width: 18px;
        height:10px;
        display: flex;
        margin: auto;
        margin-right: 0px;
      }
    }
    &:hover, &:focus, &:active {
      border-left: 5px solid #F6797D;
      border-bottom:none;
        >a {
          text-decoration: none;
          color: #F6797D;
          display: flex;
          >svg{
            display: flex;
            margin: auto;
            margin-right: 0px;
            width: 18px;
            height:10px;
          }
        }
    }
  }
  &:hover ul {
    display: block;
  }
  .platform-div {
    justify-content: center;
    
  .blank{display:none;
  position:absolute;
  background-color: white;
  top: 0px;
  left: -500px;
  min-width:2200px;
  height:100%;
  z-index:-1;

}
    list-style: none;
    display: none;
    position: absolute;
    min-height: 200px;
    flex-direction: row;
    flex-wrap:wrap;
    width: 1300px;
    
    top: 70px;
    left: 0;
    justify-content: flex-start;
    background-color: white;
    font-family: ${BoldRubikFont};
    z-index: 1;
    align-items:flex-start;
    >div:last-child{
      cursor: default;
     width:260px;
      padding-right:25px;
      color:#24395A;
      h1{font-size: 16px;
        line-height: 19px;}
        &:hover{
          h1{
          color: #F6797D;
          }
          p{
            color: #F6797D;
           
            }
          div{
              svg{
              fill : #F6797D;
              path{
                fill : #F6797D;
              }
              }
            }
          }
    }
    >div{
      width:260px;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: left;
      >h1{
        font-family: ${BoldRubikFont};
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 5px 0;
        color: #24395A;
        padding: 8px 0px 8px 0px;
      }
      .submenu{
        font-family: ${BoldRubikFont};
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 5px 0;
        color: #24395A;
        padding: 8px 0px 8px 0px;
      }
      >p{
        font-family: ${MontserratRegular};
        font-style: normal;
        font-size: 12px;
        line-height: 15px;
        color: #3A3A3A;
      }
      &:hover{
        >h1{
        color: #F6797D;
        }
        >p{
          color: #F6797D;
          }
        >div{
            >svg{
            fill : #F6797D;
            >path{
              fill : #F6797D;
            }
            >defs{
              >image{
                filter: brightness(0) saturate(100%) invert(57%) sepia(27%) saturate(946%) hue-rotate(308deg) brightness(98%) contrast(96%);
                 }
              }
            }
          }
        }
      }
    }
  }
  &:hover .platform-div{
    display: flex;
    justify-content: center;
    .blank{
      display:block;
    }
  }
  ul > li:hover {
    color: #F6797D;
  }
  

  .solution-div {
    list-style: none;
    display: none;
    position: absolute;
    height: 200px;
    flex-direction: row;
    width: 100vw;
    top: 70px;
    left: -120px;
    right:-120px;
    justify-content: flex-start;
    background-color: white;
    font-family: ${BoldRubikFont};
    z-index: 1;
    justify-content: flex-start;
    >div:last-child{
      cursor: default;
    }
    >div{
      width:20%;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: left;
      >h1{
        font-family: ${BoldRubikFont};
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 5px 0;
        color: #24395A;
        padding: 8px 0px 8px 0px;
      }
      >p{
        font-family: ${MontserratRegular};
        font-style: normal;
        font-size: 12px;
        line-height: 15px;
        color: #3A3A3A;
      }
      &:hover{
        >h1{
        color: #F6797D;
        }
        >p{
          color: #F6797D;
          }
        >div{display: flex;
            >svg{
            fill : #F6797D;
            >path{
              fill : #F6797D;
            }
            >defs{
              >image{
                filter: brightness(0) saturate(100%) invert(57%) sepia(27%) saturate(946%) hue-rotate(308deg) brightness(98%) contrast(96%);
              }}
            }
            >h1{
              font-family: ${BoldRubikFont};
              font-style: normal;
              font-size: 16px;
              line-height: 19px;
              margin: 5px 0;
              color: #24395A;
              padding: 8px 0px 8px 0px;
            }
          }
        }
      }
    }
  }
  &:hover .solution-div{
    display: flex;
  }
  ul > li:hover {
    color: #F6797D;
  }
`;

const MenuLinks = styled.a`
  font-size: 16px;
  text-transform: capitalize;
  color: #979ba0;
  font-family: ${RegularFont};
  text-decoration: none;
  >div{
    >img{
   width: 16px;
    }
  }
  @media(max-width: 1200px){
    >svg{
      width: 18px;
      height: 10px;
    }
  }
`;

const MenuList = styled.ul`
  display: flex;
  margin-bottom: -10px !important;
  @media(max-width: 1200px){
    ul > li:hover {
    color: #F6797D;
    }
  
    .platform-div{
    justify-content: center;
      >div:last-child{
        cursor: default;
      }
      >div{
        cursor: pointer;
        padding: 10px 20px;
        p{
          font-family: ${MontserratRegular}
          font-size: 12px;
        }
        >div{
          display: flex;
          align-items: center;
          >h1 {
            margin: 0 15px;
            font-size: 16px;
            font-family: ${BoldRubikFont}
          }
          .submenu{
            margin: 0 15px;
            font-size: 16px;
            font-family: ${BoldRubikFont}
      }
        }
        &:hover{
          >div{
            >h1{
            color: #F6797D;
            }
            >div{
              >svg {
                fill : #F6797D;
                >path{
                  fill : #F6797D;
                }
              }
            }
          }
        }
      }

      >div:nth-child(1){
        background: ${({ backgroundfirst }) => backgroundfirst ? backgroundfirst : ''};
      }
      >div:nth-child(2){
        background: ${({ backgroundsecond }) => backgroundsecond ? backgroundsecond : ''};
      }
      >div:nth-child(3){
        background: ${({ backgroundthird }) => backgroundthird ? backgroundthird : ''};
      }
      >div:nth-child(4){
        background: ${({ backgroundfourth }) => backgroundfourth ? backgroundfourth : ''};
      }
      >div:nth-child(5){
        background: ${({ backgroundfifth }) => backgroundfifth ? backgroundfifth : ''};
      }

      >div:last-child{
        padding: 16px 20px;
        >h1{
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          padding-bottom: 7px;
          color: #243A5A;
        }
        >ul{
          padding-left: 20px;
          li{
            font-family: Rubik;
            padding: 5px 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #979797;
          }
        }
      }
    }



    .platform-div-new{
      >div:last-child{
        cursor: default;
      }
      >div{
        cursor: pointer;
        padding: 10px 20px;
        p{
          font-family: ${MontserratRegular}
          font-size: 12px;
        }
        >div{
          display: flex;
          align-items: center;
          >h1 {
            margin: 0 15px;
            font-size: 16px;
            font-family: ${BoldRubikFont}
          }
        }
        &:hover{
          >div{
            >h1{
            color: #F6797D;
            }
            >div{
              >svg {
                fill : #F6797D;
                >path{
                  fill : #F6797D;
                }
                >defs{
                  >image{
                    filter: brightness(0) saturate(100%) invert(57%) sepia(27%) saturate(946%) hue-rotate(308deg) brightness(98%) contrast(96%);
                  }
                }
              }
            }
          }
        }
      }

      >div:nth-child(1){
        background: ${({ backgroundsix }) => backgroundsix ? backgroundsix : ''};
      }
      >div:nth-child(2){
        background: ${({ backgroundseven }) => backgroundseven ? backgroundseven : ''};
      }
      >div:nth-child(3){
        background: ${({ backgroundeight }) => backgroundeight ? backgroundeight : ''};
      }
      >div:nth-child(4){
        background: ${({ backgroundnine }) => backgroundnine ? backgroundnine : ''};
      }
      >div:nth-child(5){
        background: ${({ backgroundten }) => backgroundten ? backgroundten : ''};
      }
      >div:nth-child(6){
        background: ${({ backgroundeleven }) => backgroundeleven ? backgroundeleven : ''};
      }

      

      >div:last-child{
        // background: rgba(246, 121, 125, 0.14);
        padding: 16px 20px;
        >h1{
          font-family: Rubik;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          padding-bottom: 7px;
          color: #243A5A;
        }
        >ul{
          padding-left: 20px;
          li{
            font-family: Rubik;
            padding: 5px 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #979797;
          }
        }
      }
    }


  }
`;

const Logo = styled.div`
  height: 40px;
  float: left;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  
  > img {
    height: 100%;
  }
`;

const LinksContainer = styled.div`
  display: contents;
  width: 100%;
  justify-content: space-around;
  .hamburger-icon{
    display:none;
    position: absolute;
    right: 50px;
    top: 30px;
    @media (max-width: 500px){
      right: 20px;
      top:21px
    }
  }
  @media (min-width: 1201px) {
    float: left;
  }

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0;
    .hamburger-icon{
      display:flex;
      width: ${({ width }) => width ? width : ''};
      height: ${({ height }) => height ? height : ''};
      >img{
        width: 100%;
        height: 100%;
      }
    }
    >div:last-child{
      display:none;
    }
  }
`;

const HamBurgerMenu = styled.div`
  padding: 6px 20px;
  font-size: 23px;
  display: inline-block;
  position: relative;
  float: right;
  
  > i {
    cursor: pointer;
    color: #3e7f9f;
  }

  @media (min-width: 1201px) {
    display: none;
  }
  
  @media (max-width: 600px) {
    padding-right: 0;
  }
`;

const CustomArrow = styled.span`
  margin-top: 7px;
  margin-left: 5px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: ${({ active }) => active ? '4px solid  #49b5d6' : '4px solid #8b8b8b'};
  float: left;
  cursor: pointer;
  display: inherit;
  @media(max-width: 1200px) {
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    border-left: ${({ active }) => active ? '4px solid  #49b5d6' : '4px solid #8b8b8b'};
    margin-top: 8px;
    margin-left: 6px;
  }

  @media(max-width: 600px) {
    margin-top: 10px;
    margin-left: 5px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: ${({ active }) => active ? '4px solid  #49b5d6' : '4px solid #8b8b8b'};
  }

`;

const NavLinkContainer = styled.div`
  display:block;
  padding: 0 0 0 10px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const SubMenu = styled.ul`
  position: absolute;
  padding: 10px 0;
  margin: 0 0 0 0px;
  top: 85px;
  font-size: 13px;
  border-radius: 4px;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  z-index: 1;
  > div {
    margin: 0px;
    float: left;
    width: 100%;
    height: 100%;
    > li {
      display: block;
      text-align: left;
      width: 100%;
      float: left;
      > a {
        text-transform: none;
      }
    }
  }
  @media(min-width: 601px) {
    background-color: white;
    float: left;
    width: 200px;
  }
  @media(max-width: 1200px) {
    top: auto;
    left: 157px;
  }
  @media(max-width: 600px) {
    position: relative;
    top: 10px;
    left: 0px;
    border-radius: 0px;
    box-shadow: none;
    z-index: 0;
  }
`;

const ResponsiveLinks = styled.div`
  display:none;
`;

const ResponsiveNavContainer = styled.div`
  display:none;
  width: 100%;
  @media(max-width: 1200px){
    display: flex;
    flex-flow: column;
    width: 100%;
    justify-content: space-between;
    >ul{
      display: flex;
      flex-flow: column;
      margin-top: 10px;
      padding-left: 107px;
      width: 100%;
      >li{
        width: 100%;
        margin-left: 0;
        border-bottom: none;
        >a{
          width: 100%;
          padding: 10px;
          >p{
            float: left;
            margin-bottom: 0;
          }
          >svg{
            float: right;
          }
        }
     }
    }
    >div{
      display: flex;
      width: 100%;
      align-items: center;
      flex-flow: column;
      padding-top: 25px;
      >button:first-child{
        width:80%;
        margin: 0;
      }
      >button:last-child{
        width:80%;
        margin: 10px 0;

      }
    }
  }
  @media(max-width: 670px){
    >ul{
      padding-left: 0px;
    }
  }

  @media(max-width: 600px){
    display: block;
    >ul{
      >li{
        >a{
          font-family: Rubik-Medium !important;
          text-transform: uppercase;
        }
      }
    }
  }
`;

const NavBar = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const NavBarDiv = styled.div`
  width: 1000px;
  height: auto;
  display: flex;
  `;

export { SubMenu, MenuLinks, MenuList, ButtonContainer, Button, Logo, NavBarContainer,
  LinksContainer, HamBurgerMenu, PageLinkWrapper, PageLinkContainer, CustomListItem, CustomListItemNavBar,
  CustomArrow, NavLinkContainer, Sigin, PortalButton, ResponsiveLinks, ResponsiveNavContainer, NavBar, NavBarDiv};
